@font-face {
  font-family: "ms-icons";
  src: url("../fonts/icons/icons.eot?c9f4c393f30b0f8f74dbd1f64daad06f?#iefix") format("embedded-opentype"), url("../fonts/icons/icons.woff2?c9f4c393f30b0f8f74dbd1f64daad06f") format("woff2"), url("../fonts/icons/icons.woff?c9f4c393f30b0f8f74dbd1f64daad06f") format("woff"), url("../fonts/icons/icons.ttf?c9f4c393f30b0f8f74dbd1f64daad06f") format("truetype"), url("../fonts/icons/icons.svg?c9f4c393f30b0f8f74dbd1f64daad06f#icons") format("svg");
  font-display: block;
}

@font-face {
  font-family: 'Univers Light Italic';
  src: url("../fonts/univers/Univers-Light-Italic.eot");
  src: url("../fonts/univers/Univers-Light-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/univers/Univers-Light-Italic.woff2") format("woff2"), url("../fonts/univers/Univers-Light-Italic.woff") format("woff"), url("../fonts/univers/Univers-Light-Italic.ttf") format("truetype"), url("../fonts/univers/Univers-Light-Italic.svg#Univers-Light-Italic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url("../fonts/univers/UniversLTStd-Obl.eot");
  src: url("../fonts/univers/UniversLTStd-Obl.eot?#iefix") format("embedded-opentype"), url("../fonts/univers/UniversLTStd-Obl.woff2") format("woff2"), url("../fonts/univers/UniversLTStd-Obl.woff") format("woff"), url("../fonts/univers/UniversLTStd-Obl.ttf") format("truetype"), url("../fonts/univers/UniversLTStd-Obl.svg#UniversLTStd-Obl") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url("../fonts/univers/UniversLTStd-Bold.eot");
  src: url("../fonts/univers/UniversLTStd-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/univers/UniversLTStd-Bold.woff2") format("woff2"), url("../fonts/univers/UniversLTStd-Bold.woff") format("woff"), url("../fonts/univers/UniversLTStd-Bold.ttf") format("truetype"), url("../fonts/univers/UniversLTStd-Bold.svg#UniversLTStd-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url("../fonts/univers/UniversLTStd-BoldObl.eot");
  src: url("../fonts/univers/UniversLTStd-BoldObl.eot?#iefix") format("embedded-opentype"), url("../fonts/univers/UniversLTStd-BoldObl.woff2") format("woff2"), url("../fonts/univers/UniversLTStd-BoldObl.woff") format("woff"), url("../fonts/univers/UniversLTStd-BoldObl.ttf") format("truetype"), url("../fonts/univers/UniversLTStd-BoldObl.svg#UniversLTStd-BoldObl") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Univers-Light-Normal';
  src: url("../fonts/univers/Univers-Light-Normal.eot");
  src: url("../fonts/univers/Univers-Light-Normal.eot?#iefix") format("embedded-opentype"), url("../fonts/univers/Univers-Light-Normal.woff2") format("woff2"), url("../fonts/univers/Univers-Light-Normal.woff") format("woff"), url("../fonts/univers/Univers-Light-Normal.ttf") format("truetype"), url("../fonts/univers/Univers-Light-Normal.svg#Univers-Light-Normal") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url("../fonts/univers/UniversLTStd.eot");
  src: url("../fonts/univers/UniversLTStd.eot?#iefix") format("embedded-opentype"), url("../fonts/univers/UniversLTStd.woff2") format("woff2"), url("../fonts/univers/UniversLTStd.woff") format("woff"), url("../fonts/univers/UniversLTStd.ttf") format("truetype"), url("../fonts/univers/UniversLTStd.svg#UniversLTStd") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Univers Blackest';
  src: url("../fonts/univers/Univers-Black.eot");
  src: url("../fonts/univers/Univers-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/univers/Univers-Black.woff2") format("woff2"), url("../fonts/univers/Univers-Black.woff") format("woff"), url("../fonts/univers/Univers-Black.ttf") format("truetype"), url("../fonts/univers/Univers-Black.svg#Univers-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Univers Blackest Italic';
  src: url("../fonts/univers/Univers-Black-Italic.eot");
  src: url("../fonts/univers/Univers-Black-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/univers/Univers-Black-Italic.woff2") format("woff2"), url("../fonts/univers/Univers-Black-Italic.woff") format("woff"), url("../fonts/univers/Univers-Black-Italic.ttf") format("truetype"), url("../fonts/univers/Univers-Black-Italic.svg#Univers-Black-Italic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Univers Condensed Bold';
  src: url("../fonts/univers/Univers-Condensed-Bold.eot");
  src: url("../fonts/univers/Univers-Condensed-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/univers/Univers-Condensed-Bold.woff2") format("woff2"), url("../fonts/univers/Univers-Condensed-Bold.woff") format("woff"), url("../fonts/univers/Univers-Condensed-Bold.ttf") format("truetype"), url("../fonts/univers/Univers-Condensed-Bold.svg#Univers-Condensed-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
